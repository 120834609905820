// i18next-extract-mark-ns-start products-vectis-technical-papers

import { VECTIS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisPublicationsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Published papers</h2>
						<ul>
							<li>
								<p>
									Development of a New 2.0L I4 Turbocharged
									Gasoline Direct Injection Engine (
									<a
										href="http://papers.sae.org/2016-01-1017/"
										target="_blank"
										rel="noreferrer">
										SAE 2016-01-1017
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Development of a New 1.5L I4 Turbocharged
									Gasoline Direct Injection Engine (
									<a
										href="http://papers.sae.org/2016-01-1020/"
										target="_blank"
										rel="noreferrer">
										SAE 2016-01-1020
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Development of New 3.5 L V6 Turbocharged
									Gasoline Direct Injection Engine (
									<a
										href="http://papers.sae.org/2016-01-1012/"
										target="_blank"
										rel="noreferrer">
										SAE 2016-01-1012
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Estimation of Performance Characteristics of a
									Split Cycle Based SI Engine (
									<a
										href="http://papers.sae.org/2016-28-0090/"
										target="_blank"
										rel="noreferrer">
										SAE 2016-28-0090
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									The Drive for Minimum Fuel Consumption of
									Passenger Car Diesels: An Analytical Study to
									Evaluate Key Architectural Choices (
									<a
										href="http://papers.sae.org/2015-24-2519/"
										target="_blank"
										rel="noreferrer">
										SAE 2015-24-2519
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Predictive Simulation of PFI Engine Combustion
									and Emission (
									<a
										href="http://papers.sae.org/2013-32-9169/"
										target="_blank"
										rel="noreferrer">
										SAE 2013-32-9169
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Performance Development of a New Tier 4 Final
									Engine Family below 56 kW (
									<a
										href="http://papers.sae.org/2013-24-0125/"
										target="_blank"
										rel="noreferrer">
										SAE 2013-24-0125
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Effects of Intake Port Geometry on the
									Performance of an SI Engine (
									<a
										href="http://papers.sae.org/2011-32-0506/"
										target="_blank"
										rel="noreferrer">
										SAE 2011-32-0506
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Multiple-Cylinder Diesel Engine CFD Simulation
									Using VECTIS&nbsp; (
									<a
										href="http://papers.sae.org/2009-32-0164/"
										target="_blank"
										rel="noreferrer">
										SAE 2009-32-0164
									</a>
									)
								</p>
							</li>
						</ul>
						<h2>Legacy publications</h2>
						<ul>
							<li>
								Intake manifold optimization using CFD analysis,{' '}
								<a
									href="/documents/paper_intake-manifold-optimization-using-cfd-analysis_hb_02-25-2002.pdf"
									target="_blank"
									rel="noreferrer">
									click here
								</a>
							</li>
							<li>
								A practical approach to water jacket flow analysis,{' '}
								<a
									href="/documents/paper_a-practical-approach-to-water-jacket-flow-analysis.pdf"
									target="_blank"
									rel="noreferrer">
									click here
								</a>
							</li>
							<li>
								Application of LDA and PIV techniques to the
								validation of VECTIS using boundary mesh motion,{' '}
								<a
									href="/documents/paper_application-of-lda-and-piv-techniques-to-the-validation-of-vectis-using-boundary-mesh-motion.pdf"
									target="_blank">
									click here
								</a>
							</li>
							<li>
								Underhood airflow prediction using VECTIS coupled to
								a 1-D system model,{' '}
								<a
									href="/documents/paper_underhood-airflow-prediction-using-vectis-coupled-to-a-1-d-system-model_tgb-sms-djb_02-28-2000.pdf"
									target="_blank">
									click here
								</a>
							</li>
							<li>
								Combined WAVE-VECTIS simulation of an intake
								manifold of V6 PFI gasoline engine,{' '}
								<a
									href="/documents/paper_combined-wave-vectis-simulation-of-an-intake-manifold-of-v6-pfi-gasoline-engine.pdf"
									target="_blank">
									click here
								</a>
							</li>
							<li>
								The use of VECTIS in Honda's VTEC lean burn engine
								development,{' '}
								<a
									href="/documents/paper_the-use-of-vectis-in-honda-s-vtec-lean-burn-engine-development_tt-ni-ah-ai_02-25-2002.pdf"
									target="_blank">
									click here
								</a>
							</li>
							<li>
								Coupled WAVE-VECTIS simulation of an intake
								restricted engine,{' '}
								<a
									href="/documents/university-of-minnesota-fsae_coupled-wave-vectis-simulation-of-an-intake-restricted-engine.pdf"
									target="_blank">
									click here
								</a>
							</li>
						</ul>
					</Trans>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_CapabilitiesApplications")}
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductVectisPublicationsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis-technical-papers", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
}
`;

